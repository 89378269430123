<template>
  <div class="relative" id="contentModal" v-if="isActive">
    <!-- Desktop -->
    <div
      v-if="isActive"
      class="
        fixed
        inset-0
        bg-black
        bg-opacity-70
        hidden
        lg:flex
        justify-center
        items-end
        pb-5
      "
      style="z-index: 1001"
    >
      <div
        id="modal"
        :class="`bg-white rounded-cu fixed shadow-blue ${customClass}`"
        :style="`position: relative;`"
      >
        <div
          v-if="!hiddenHeader"
          class="
            flex
            items-center
            justify-betwwen
            border-0 border-b border-solid border-gray-300
            bg-[#f9f9f9] rounded-t-lg
            px-2
            py-3
          "
        >
          <i
            @click="exitModal"
            class="
              fas
              fa-times
              text-blackdok
              hover:text-dokBlue-ultra
              cursor-pointer
            "
          ></i>
        </div>
        <div
          class="overflow-x-hidden"
          :class="
            noPadding
              ? `py-0 ${!hasNoScroll ? 'overflow-y-scroll scroll' : ''}`
              : `py-2 ${!hasNoScroll ? 'overflow-y-scroll scroll' : ''}`
          "
          :style="
            `width: ${width ? `${width}px` : '500px'}; min-width: ${
              minWidth ? `${minWidth}px` : '100%'
            }px; max-width: ${maxWidth ? `${maxWidth}px` : '100%'}; height: ${
              height ? `${height}px` : 'auto'
            }; min-height:  ${minHeight ? `200px` : 'auto'}; max-height: ${
              maxHeight ? `${maxHeight}px` : '850px'
            }`
          "
        >
          <slot></slot>
        </div>
      </div>
    </div>
    <!--Mobile-->
    <div
      :class="
        `
        fixed 
        lg:hidden 
        bottom-0 
        inset-x-0 
        scrolling-touch
        overflow-y-scroll
        overflow-hidden
        max-h-[450px]
        scroll z-500 
        bg-white 
        shadow-xl 
        ${customClassMobile}`
      "
    >
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
.scroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 16px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 100vh;
    @apply bg-gray-200;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @apply bg-dokBlue-ultra;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-300;
  }
}
</style>

<script>
export default {
  props: {
    customClassMobile: {
      type: String,
      required: false,
      default: ""
    },
    customClass: {
      type: String,
      required: false,
      default: ""
    },
    title: {
      type: String,
      required: false
    },
    noPadding: {
      type: Boolean,
      required: false
    },
    isActive: {
      type: Boolean,
      required: true
    },
    exitModal: {
      type: Function,
      required: true
    },
    width: {
      type: Number,
      required: false
    },
    maxWidth: {
      type: Number,
      required: false
    },
    minWidth: {
      type: Number,
      required: false
    },
    height: {
      type: Number,
      required: false
    },
    minHeight: {
      type: Number,
      required: false
    },
    maxHeight: {
      type: Number,
      required: false
    },
    hiddenHeader: {
      type: Boolean,
      required: false
    },
    hasNoScroll: {
      type: Boolean,
      required: false
    }
  }
};
</script>
